import React, { useState, useRef, useEffect } from 'react';
import { navigate, Link } from "gatsby"
import { useCartActionContext } from "./cart-context";
import { FormInputText, FormColorPickerButton, FormSelect, FormButton, FormRadioGroup, FormCheckbox } from '../components/form-elements';
import { getMessageTopperPrice } from "../helpers/cake-message-price-helper";
import { getDietaryOptionPrice } from "../helpers/dietary-option-price-helper";
import { getPetiteDecorationPrice } from "../helpers/petite-decoration-price-helper";
import { GlutenFreeIcon, VeganIcon, DairyFreeIcon } from "../components/dietary-options";
import { FLAVOURS } from "../constants";

export default function CakeForm({ cake }) {
  const cakeRef = useRef(cake);
  const {
    textures,
    flavours,
    options,
    decorations,
    cakeShapes,
    set1Colours,
    set2Colours,
    set3Colours,
    set4Colours,
    set5Colours,
    set6Colours,
    set7Colours,
    set8Colours,
    set9Colours,
    set10Colours,
    dietaryOptions
  } = getCakeProperties(cakeRef.current);
  const { addCake, updateCake } = useCartActionContext();
  const cakeAction = cake.key ? updateCake : addCake;
  const messageTopperProduct = getMessageTopperPrice();
  const petiteDecorationProduct = getPetiteDecorationPrice();
  const dietaryOptionProduct = getDietaryOptionPrice();
  const getFirstOrDefault = (value, array) => value ? value : (array ? array[0] : null);

  const [price, setPrice] = useState();
  const [selectedPriceId, setCakePriceId] = useState(() => getFirstOrDefault(cake.selectedPriceId, options.map(x => x.id)));
  const [selectedFlavourRaw, setCakeFlavourRaw] = useState(() => getFirstOrDefault(cake.selectedFlavourRaw, flavours));
  const [ingredients, setIngredients] = useState([]);
  const [selectedTexture, setCakeTexture] = useState(() => getFirstOrDefault(cake.selectedTexture, textures));
  
  const [selectedCakeShape, setCakeShape] = useState(() => getFirstOrDefault(cake.selectedCakeShape, cakeShapes));
  const [selectedSet1Colour, setCakeSet1Colour] = useState(() => getFirstOrDefault(cake.selectedSet1Colour, set1Colours));
  const [selectedSet2Colour, setCakeSet2Colour] = useState(() => getFirstOrDefault(cake.selectedSet2Colour, set2Colours));
  const [selectedSet3Colour, setCakeSet3Colour] = useState(() => getFirstOrDefault(cake.selectedSet3Colours, set3Colours));
  const [selectedSet4Colour, setCakeSet4Colour] = useState(() => getFirstOrDefault(cake.selectedSet4Colours, set4Colours));
  const [selectedSet5Colour, setCakeSet5Colour] = useState(() => getFirstOrDefault(cake.selectedSet5Colours, set5Colours));
  const [selectedSet6Colour, setCakeSet6Colour] = useState(() => getFirstOrDefault(cake.selectedSet6Colours, set6Colours));
  const [selectedSet7Colour, setCakeSet7Colour] = useState(() => getFirstOrDefault(cake.selectedSet7Colours, set7Colours));
  const [selectedSet8Colour, setCakeSet8Colour] = useState(() => getFirstOrDefault(cake.selectedSet8Colours, set8Colours));
  const [selectedSet9Colour, setCakeSet9Colour] = useState(() => getFirstOrDefault(cake.selectedSet9Colours, set9Colours));
  const [selectedSet10Colour, setCakeSet10Colour] = useState(() => getFirstOrDefault(cake.selectedSet10Colours, set10Colours));

  const [selectedPetiteDecoration, setCakeDecoration] = useState(() => getFirstOrDefault(cake.selectedDecoration, decorations));
  const [cakeTopperMessage, setCakeTopperMessage] = useState(() => cake.cakeTopperMessage ? cake.cakeTopperMessage : "");
  const [cakeTopperType, setCakeTopperType] = useState(() => cake.cakeTopperType ? cake.cakeTopperType : "");

  const [selectedDietaryOptionIsGF, setSelectedDietaryOptionIsGF] = useState(() => cake.selectedDietaryOptionIsGF ?? false);
  const [selectedDietaryOptionIsV, setSelectedDietaryOptionIsV] = useState(() => cake.selectedDietaryOptionIsV ?? false);
  const [selectedDietaryOptionIsDF, setSelectedDietaryOptionIsDF] = useState(() => cake.selectedDietaryOptionIsDF ?? false);

  const updateCakeTopperMessage = (e) => {
    setCakeTopperMessage(e().substring(0, 50));
  }

  let availableDietaryOptions = "";
  (() => {
    if (!selectedFlavourRaw) { availableDietaryOptions = dietaryOptions; return; }

    const start = selectedFlavourRaw.indexOf('[');
    const end = selectedFlavourRaw.indexOf(']');
    availableDietaryOptions = (start !== -1 && end !== -1) ? selectedFlavourRaw.substring(start + 1, end).replace("|", ",") : "none";
    availableDietaryOptions = availableDietaryOptions === "none" ? "" : (availableDietaryOptions.trim() === "" ? dietaryOptions : availableDietaryOptions);
  })();

  const getSelectedFlavour = (value) => {
    if (!value) return value;
    const index = value.indexOf('[');
    let label = value;
    if (index !== -1) {
      label = value.substring(0, index);
    }
    return label;
  };

  const getTotalPrice = () => {
    const topperPrice = cakeTopperType && cakeTopperMessage.trim() ? messageTopperProduct.price : 0;
    const petiteDecorationPrice = 
      selectedPetiteDecoration && 
      selectedPetiteDecoration.toLowerCase() !== 'none' ? petiteDecorationProduct.price: 0;

    console.log(petiteDecorationPrice, selectedPetiteDecoration, !selectedPetiteDecoration)
    const dietaryOptionPrice =
      selectedDietaryOptionIsGF ||
        selectedDietaryOptionIsV ||
        selectedDietaryOptionIsDF ? dietaryOptionProduct.price : 0;
    const totalPrice = price 
                        + topperPrice 
                        + dietaryOptionPrice
                        + petiteDecorationPrice;
                    
    return totalPrice;
  }

  useEffect(() => {
    if (!selectedFlavourRaw) return null;

    const parsedFlavour = getSelectedFlavour(selectedFlavourRaw).replace("*", "").trim();
    const flavour = FLAVOURS.find(x => x.name.toLocaleUpperCase() === parsedFlavour.toLocaleUpperCase());
    setIngredients(flavour ? flavour.ingredients : []);

  }, [selectedFlavourRaw])

  useEffect(() => {
    const item = options.find(x => x.id === selectedPriceId)
    cakeRef.current = {
      ...cakeRef.current
      , selectedPriceId
      , selectedSize: item.size
      , selectedPrice: item.price
      , selectedFlavourRaw: selectedFlavourRaw
      , selectedFlavour: getSelectedFlavour(selectedFlavourRaw)
      , selectedTexture
      , selectedDecoration: getSelectedDecoration(selectedPetiteDecoration)
      , selectedCakeShape
      , selectedSet1Colour
      , selectedSet2Colour
      , selectedSet3Colour
      , selectedSet4Colour
      , selectedSet5Colour
      , selectedSet6Colour
      , selectedSet7Colour
      , selectedSet8Colour
      , selectedSet9Colour
      , selectedSet10Colour
      , cakeTopperMessage: cakeTopperType ? cakeTopperMessage.trim() : ""
      , cakeTopperType
      , selectedDietaryOptionIsDF: selectedDietaryOptionIsDF && availableDietaryOptions.includes("df")
      , selectedDietaryOptionIsV: selectedDietaryOptionIsV && availableDietaryOptions.includes("v")
      , selectedDietaryOptionIsGF: selectedDietaryOptionIsGF && availableDietaryOptions.includes("gf")
    }
    setPrice(cakeRef.current.selectedPrice);
  }, [selectedPriceId
    , selectedFlavourRaw
    , selectedTexture
    , selectedPetiteDecoration
    , selectedCakeShape
    , cakeTopperMessage
    , cakeTopperType
    , selectedSet1Colour
    , selectedSet2Colour
    , selectedSet3Colour
    , selectedSet4Colour
    , selectedSet5Colour
    , selectedSet6Colour
    , selectedSet7Colour
    , selectedSet8Colour
    , selectedSet9Colour
    , selectedSet10Colour
    , selectedDietaryOptionIsDF
    , selectedDietaryOptionIsV
    , selectedDietaryOptionIsGF
  ]);

  return (
    <>
      <div className='flex justify-between items-end mt-5'>
        <h3 className="font-ovo uppercase text-2xl md:text-3xl tracking-wider text-gray-800 mb-7">{cake.name}</h3>
        <h3 className="font-ovo text-3xl md:text-[2rem] tracking-wider text-gray-800 mb-[1.7rem] lg:mb-[1.8rem]">${getTotalPrice()}</h3>
      </div>
      <p className="font-gotu text-justify text-xs md:text-[.80rem] tracking-wider leading-[1.3rem] md:leading-[1.6rem] text-gray-800 mb-4">
        {cake.description}
      </p>
      <div className="grid grid-cols-1 gap-6 max-w-2xl mx-auto mt-8">
        <FormSelect
          label={"SIZE"}
          value={selectedPriceId} onChange={setCakePriceId}
          options={options.map(x => { return { value: x.id, label: `${x.size}` }; })}
        />
        <FormSelect
          label={"FLAVOUR"}
          sublabel={<AllergenWarning />}
          value={selectedFlavourRaw} onChange={setCakeFlavourRaw}
          options={flavours?.map(x => { return { value: x, label: getSelectedFlavour(x) }; })}
        />
        <Ingredients ingredients={ingredients} />
        <DietaryOptionsForm
          setSelectedDietaryOptionIsGF={setSelectedDietaryOptionIsGF}
          setSelectedDietaryOptionIsV={setSelectedDietaryOptionIsV}
          setSelectedDietaryOptionIsDF={setSelectedDietaryOptionIsDF}
          selectedDietaryOptionIsDF={selectedDietaryOptionIsDF}
          selectedDietaryOptionIsV={selectedDietaryOptionIsV}
          selectedDietaryOptionIsGF={selectedDietaryOptionIsGF}
          dietaryOptions={availableDietaryOptions}
          flavours={flavours} />
        <FormSelect
          label={"TEXTURE"}
          value={selectedTexture} onChange={setCakeTexture}
          options={textures?.map(x => { return { value: x, label: x }; })}
        />
        <FormSelect
          label={"DECORATION"}
          value={selectedPetiteDecoration} onChange={setCakeDecoration}
          options={decorations?.map(x => { return { value: x, label: x }; })}
        />
        <FormSelect
          label={"SHAPE"}
          value={selectedCakeShape} onChange={setCakeShape}
          options={cakeShapes?.map(x => { return { value: x, label: x }; })}
        />

        <FormColorPickerButton
          label={cakeRef.current.set1ColourLabel}
          value={selectedSet1Colour}
          onChange={setCakeSet1Colour}
          groupName={"set1Colours"}
          options={set1Colours?.map(x => { return { value: x, label: x }; })}
        />
        <FormColorPickerButton
          label={cakeRef.current.set2ColourLabel}
          value={selectedSet2Colour}
          onChange={setCakeSet2Colour}
          groupName={"set2Colours"}
          options={set2Colours?.map(x => { return { value: x, label: x }; })}
        />
        <FormColorPickerButton
          label={cakeRef.current.set3ColourLabel}
          value={selectedSet3Colour}
          onChange={setCakeSet3Colour}
          groupName={"set3Colours"}
          options={set3Colours?.map(x => { return { value: x, label: x }; })}
        />
        <FormColorPickerButton
          label={cakeRef.current.set4ColourLabel}
          value={selectedSet4Colour}
          onChange={setCakeSet4Colour}
          groupName={"set4Colours"}
          options={set4Colours?.map(x => { return { value: x, label: x }; })}
        />
        <FormColorPickerButton
          label={cakeRef.current.set5ColourLabel}
          value={selectedSet5Colour}
          onChange={setCakeSet5Colour}
          groupName={"set5Colours"}
          options={set5Colours?.map(x => { return { value: x, label: x }; })}
        />
        <FormColorPickerButton
          label={cakeRef.current.set6ColourLabel}
          value={selectedSet6Colour}
          onChange={setCakeSet6Colour}
          groupName={"set6Colours"}
          options={set6Colours?.map(x => { return { value: x, label: x }; })}
        />

        <FormColorPickerButton
          label={cakeRef.current.set7ColourLabel}
          value={selectedSet7Colour}
          onChange={setCakeSet7Colour}
          groupName={"set7Colours"}
          options={set7Colours?.map(x => { return { value: x, label: x }; })}
        />
        <FormColorPickerButton
          label={cakeRef.current.set8ColourLabel}
          value={selectedSet8Colour}
          onChange={setCakeSet8Colour}
          groupName={"set8Colours"}
          options={set8Colours?.map(x => { return { value: x, label: x }; })}
        />
        <FormColorPickerButton
          label={cakeRef.current.set9ColourLabel}
          value={selectedSet9Colour}
          onChange={setCakeSet9Colour}
          groupName={"set9Colours"}
          options={set9Colours?.map(x => { return { value: x, label: x }; })}
        />
        <FormColorPickerButton
          label={cakeRef.current.set10ColourLabel}
          value={selectedSet10Colour}
          onChange={setCakeSet10Colour}
          groupName={"set10Colours"}
          options={set10Colours?.map(x => { return { value: x, label: x }; })}
        />

        <div>
          <div className='flex flex-col sm:flex-row sm:items-center mb-2'>
            <h1 className='font-ovo uppercase text-sm md:text-base tracking-wider text-gray-800 mr-2'>MESSAGE ON CAKE +${messageTopperProduct.price}</h1>
            {/* <span className='font-light text-sm'>(automatically added to cake total)</span> */}
          </div>
          <div className="flex justify-between flex-col sm:flex-row mb-3.5 mt-3">
            <label className='flex items-center mb-2'>
              <FormRadioGroup
                label={""}
                value={cakeTopperType}
                onChange={setCakeTopperType}
                groupName={"cakeTopperType"}
                inline={true}
                options={[
                  { value: "", labels: [] },
                ]}
              /><span className='font-gotu text-xs md:text-[.80rem] tracking-wider text-pas-gray -ml-4 -mb-1 inline-block align-bottom'>None</span></label>
            <label className='flex items-center mb-2'>
              <FormRadioGroup
                label={""}
                value={cakeTopperType}
                onChange={setCakeTopperType}
                groupName={"cakeTopperType"}
                inline={true}
                options={[
                  { value: "whiteRibbon", labels: [] },
                ]}
              /><span className='font-gotu text-xs md:text-[.80rem] tracking-wider text-pas-gray -ml-4 -mb-1 inline-block align-bottom'>White Ribbon (EDIBLE)</span></label>
            <label className='flex items-center mb-2 '>
              <FormRadioGroup
                label={""}
                value={cakeTopperType}
                onChange={setCakeTopperType}
                groupName={"cakeTopperType"}
                inline={true}
                options={[
                  { value: "2dCakeTopper", labels: [] },
                ]}
              /><span className='font-gotu text-xs md:text-[.80rem] tracking-wider text-pas-gray -ml-4 -mb-1 inline-block align-bottom'>2D Cake Topper (INEDIBLE)</span></label>
          </div>
          {cakeTopperType ? <div>
            <FormInputText label={""} value={cakeTopperMessage} onChange={updateCakeTopperMessage} maxLength={"50"} />
            <span className="text-gray-500 italic font-gotu text-[.65rem]">Max of 50 characters only ({50 - cakeTopperMessage.length} chars left)</span>

          </div> : <></>}

        </div>

        <FormButton label={cakeRef.current.key ? "UPDATE CART" : "ADD TO CART"} onClick={() => { cakeAction(cakeRef.current); navigate("/cart/") }} />
      </div>
      {/* <GeneralCakeInformation /> */}
    </>
  );
}

const getCakeProperties = (cake) => {
  return {
    textures: cake.textures,
    flavours: cake.flavours,
    options: cake.options,
    decorations: cake.decorations,
    cakeShapes: cake.cakeShapes,
    set1Colours: cake.set1Colours,
    set2Colours: cake.set2Colours,
    set3Colours: cake.set3Colours,
    set4Colours: cake.set4Colours,
    set5Colours: cake.set5Colours,
    set6Colours: cake.set6Colours,
    set7Colours: cake.set7Colours,
    set8Colours: cake.set8Colours,
    set9Colours: cake.set9Colours,
    set10Colours: cake.set10Colours,
    dietaryOptions: cake.dietaryRequirements
  };
};

function getSelectedDecoration(selectedPetiteDecoration) {
  return selectedPetiteDecoration && selectedPetiteDecoration?.toLowerCase() === 'none' ? "" : selectedPetiteDecoration;
}

function Ingredients({ ingredients }) {
  if (!ingredients || ingredients.length === 0) return <></>;
  const ingredientsElement = ingredients.map(
    (x, i) => <span key={i}>{x} | </span>
  )
  return (
    <div className='font-gotu text-xs tracking-wider text-pas-gray -mt-5 mb-3'>| {ingredientsElement}</div>
  )
}

function AllergenWarning() {
  return (
    <span>* dietary options available. Please read our  <Link to='/faqs#ALLERGIES' className='underline text-pas-orange'>allergen warning</Link>.</span>
  )
}

function AllergenWarningNoFlavour() {
  return (
    <span>Dietary options available. Please read our  <Link to='/faqs#ALLERGIES' className='underline font-semibold text-pas-orange'>allergen warning</Link>.</span>
  )
}

function DietaryOptionsForm({
  dietaryOptions
  , selectedDietaryOptionIsGF
  , setSelectedDietaryOptionIsGF
  , selectedDietaryOptionIsV
  , setSelectedDietaryOptionIsV
  , selectedDietaryOptionIsDF
  , setSelectedDietaryOptionIsDF
  , flavours
}) {
  if (!dietaryOptions) return <></>;
  const className = "max-h-8 p-1 mr-1";
  const allergenWarning = flavours ? <></>
    : <div className='font-gotu text-[.65rem] tracking-wider text-pas-gray mb-2'><AllergenWarningNoFlavour /></div>;
  return (
    <div>
      {allergenWarning}
      <div className={`flex gap-0 sm:gap-8 flex-col sm:flex-row ${!flavours ? '' : '-mt-6'}`}>
        {
          dietaryOptions?.includes("gf") ?
            <label className='flex items-center'>
              <FormCheckbox
                label={""}
                value={selectedDietaryOptionIsGF}
                onChange={setSelectedDietaryOptionIsGF}
              />
              <GlutenFreeIcon className={className} />
              <span className='font-gotu text-xs tracking-wider text-pas-gray'>no added gluten</span>
            </label>
            : <></>
        }
        {
          dietaryOptions?.includes("v") ?
            <label className='flex items-center'>
              <FormCheckbox
                label={""}
                value={selectedDietaryOptionIsV}
                onChange={setSelectedDietaryOptionIsV}
              />
              <VeganIcon className={className} />
              <span className='font-gotu text-xs tracking-wider text-pas-gray'>vegan</span>
            </label>
            : <></>
        }
        {
          dietaryOptions?.includes("df") ?
            <label className='flex items-center'>
              <FormCheckbox
                label={""}
                value={selectedDietaryOptionIsDF}
                onChange={setSelectedDietaryOptionIsDF}
              />
              <DairyFreeIcon className={className} />
              <span className='font-gotu text-xs tracking-wider text-pas-gray'>dairy-free</span>
            </label>
            : <></>
        }
      </div>
    </div>
  )
}

function GeneralCakeInformation() {
  return (
    <div className="py-10">
      <hr className="mb-8" />
      <div className="font-light text-gray-600 text-sm">

        <div className="mb-4">
          <h3 className='font-semibold'>Important note:</h3>
          <p>
            Please use our images only as a guide as each cake is crafted individually and unique on its own. For fresh flowers requests, please note that flowers may vary depending on seasonal changes and availability.
          </p>
        </div>

        <div className="mb-4">
          <h3 className='font-semibold'>Estimated serving size:</h3>
          <div>4 inch cake — 12 dessert / 24 coffee serves.</div>
          <div>6 inch cake — 32 dessert / 56 coffee serves.</div>
          <div>8 inch cake — 52 dessert / 96 coffee serves.</div>
        </div>
      </div>

    </div>
  );
}

