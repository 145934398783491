import { useStaticQuery, graphql } from "gatsby"

export const useProductExtras = () => {
  const { productExtras } = useStaticQuery(
    graphql`
      query AllStripeProductExtrasQuery {
        productExtras: allStripeProduct(
          filter: {metadata: {type: {eq: "extras"}}, active: {eq: true}}          
        ) {
            nodes {
              active
              id
              images
              livemode
              name
              description
              type
              localImage {
                childImageSharp {
                  gatsbyImageData(height: 500, placeholder: BLURRED)
                }                
                publicURL
              }
              metadata {
                type
              }
            }
        }       
      }
    `
  )
  return productExtras?.nodes
}
