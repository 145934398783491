import { usePrices } from "../hooks/use-prices";
import { useProductExtras } from "../hooks/use-product-extras";

export const getDietaryOptionPrice = () => {

  const prices = usePrices();
  const products = useProductExtras();
  const dietaryOption = products.find(x => x.name === "Dietary Option");

  const cakePrices = prices.filter(x => x.product.id === dietaryOption.id);
  const options = cakePrices.map(x => { return { id: x.id, size: x.description, price: (x.unit_amount) / 100 }; })

  return options[0];

}
